import "./index.css";

import { Grid } from "@mui/material";
import InfoCard from "../infoCard";
import React from "react";
import accidentInfoImg from "../../assets/accidentInfoImg.jpg";
import insuranceInfoImg from "../../assets/insuranceInfoImg.jpg";
import repairInfoImg from "../../assets/repairInfoImg.jpg";
import rwcInfoImg from "../../assets/rwcInfoImg.jpg";
import { useTranslation } from "react-i18next";

const InfoCardContainer = (props) => {
  const { t } = useTranslation();
  const buttontext = t("learn_more");
  return (
    // <Stack direction='row' justifyContent='space-evenly'  flexWrap='wrap'
    // className="py-0 px-6 sm:mx-0 lg:grid-cols-4 max-sm:px-0"
    // >
    //   <div className="flex flex-row justify-evenly flex-wrap max-sm:w-full">
    <Grid
      container
      sx={{ flexWrap: "wrap", justifyContent: "center" }}
      className="max-sm:grid-cols-1"
    >
      <InfoCard
        title={t("mainPage_content.info_card_insur.title")}
        description={t("mainPage_content.info_card_insur.info")}
        image={insuranceInfoImg}
        buttonText={buttontext}
        href="/insurance"
      ></InfoCard>

      <InfoCard
        title={t("mainPage_content.info_card_accident.title")}
        description={t("mainPage_content.info_card_accident.info")}
        image={accidentInfoImg}
        buttonText={buttontext}
        href="/accident"
      ></InfoCard>

      <InfoCard
        title={t("mainPage_content.info_card_repair.title")}
        description={t("mainPage_content.info_card_repair.info")}
        image={repairInfoImg}
        buttonText={buttontext}
        href="/repair"
      ></InfoCard>
      <InfoCard
        title={t("mainPage_content.info_card_rwc.title")}
        description={t("mainPage_content.info_card_rwc.info")}
        image={rwcInfoImg}
        buttonText={buttontext}
        href="/rwc"
      ></InfoCard>
    </Grid>
    //     </div>
    // </Stack>
  );
};

export default InfoCardContainer;
