import "./index.css";

import ContentHero from "../../comps/contentHero";
import React from "react";
import { Star } from "@mui/icons-material/";
import repair_img from "../../assets/repair_img.jpg";
import { useTranslation } from "react-i18next";

export default function RepairContent() {
  const { t } = useTranslation();
  const features = [
    {
      name: t("repairPage.brake_repair.title"),
      description: t("repairPage.brake_repair.description"),
      // icon: CloudArrowUpIcon,
    },
    {
      name: t("repairPage.transmission_repair.title"),
      description: t("repairPage.transmission_repair.description"),
    },
    {
      name: t("repairPage.airbag_repair.title"),
      description: t("repairPage.airbag_repair.description"),
    },
    {
      name: t("repairPage.shocks_suspension_repair.title"),
      description: t("repairPage.shocks_suspension_repair.description"),
    },
    {
      name: t("repairPage.tyres_repair.title"),
      description: t("repairPage.tyres_repair.description"),
    },
  ];
  return (
    <div className="overflow-hidden bg-white py-0 sm:py-0 max-sm:py-3 ">
      <ContentHero title={t("repairPage.banner_text")}></ContentHero>
      <div className="mx-auto max-w-full md:px-6 lg:px-32">
        <div className=" flex gap-x-8 gap-y-16 sm:gap-y-20 lg:items-start ">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-full ">
              <img
                src={repair_img}
                alt=""
                width="650px"
                style={{ float: "right", borderRadius: 10 }}
              />
              {/* <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('repairPage.title')}</p> */}
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {t("repairPage.introduction")}
              </p>
              <dl className="mt-10 mb-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none ">
                {features.map((feature) => (
                  <div
                    key={feature.name}
                    className="relative pl-9 w-1/2 max-sm:w-full"
                  >
                    <dt className="inline-block font-semibold text-gray-900">
                      <Star
                        className="absolute left-1 top-1 h-5 w-5 text-[#FE5D27]"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
