import "./index.css";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const InfoCard = ({ image, title, description, buttonText, href }) => {
  const navigate = useNavigate();
  const button_sx = { position: "absolute", top: "90%", left: "32.5%" };
  return (
    <Card
      sx={{
        width: "420px",
        height: "650px",
        position: "relative",
        margin: "10px 10px",
        minWidth: "390px",
      }}
    >
      <CardContent>
        <img src={image} alt="" className="info-card__image " />
        <Typography
          sx={{ textAlign: "center", fontSize: "25px", lineHeight: "70px" }}
        >
          {title}
        </Typography>
        <Typography>
          {" "}
          <span className="max-sm:text-xs">{description}</span>{" "}
        </Typography>
        <Button
          sx={button_sx}
          size="large"
          variant="contained"
          color="warning"
          onClick={() => {
            navigate(href);
          }}
        >
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
