import "./index.css";

import AboutUsLeft from "./aboutUsLeft";
import AboutUsRight from "./aboutUsRight";
import React from "react";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

const AboutUs = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack
      direction="row"
      sx={{ background: "white" }}
      margin={matches ? 0 : "0 3"}
      padding={matches ? "0px 10px " : "40px 70px"}
      width="100%"
      alignItems="center"
      border={"1px solid #D3D3D3"}
      flexWrap="wrap"
      justifyContent="space-evenly"
    >
      {/* <Stack width='60%' flex='1 1' minWidth='600px'> */}
      <div>
        <AboutUsLeft />
      </div>
      {/* </Stack> */}
      <div sx={{ position: "relative" }}>
        <AboutUsRight />
      </div>
    </Stack>
  );
};

export default AboutUs;
