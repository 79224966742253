import "./index.css";

import AboutUs from "../../comps/aboutUs";
import Banner from "../../comps/banner";
import BuildIcon from "@mui/icons-material/Build";
import { Divider } from "@mui/material";
import InfoCardContainer from "../../comps/infoCardList";
import React from "react";
import Stack from "@mui/material/Stack";

export default function MainPage() {
  return (
    <>
      <Stack>
        <Banner />
      </Stack>
      <Divider
        sx={{
          padding: "60px 0",
          width: "70%",
          margin: "auto",
          color: "#FE5D27",
        }}
      >
        <BuildIcon></BuildIcon>
      </Divider>
      <InfoCardContainer></InfoCardContainer>
      <Stack width="100%" marginTop="30px">
        <AboutUs />
      </Stack>
    </>
  );
}
