import "./index.css";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { NavLink, useNavigate } from "react-router-dom";

import ContactMethod from "../contacts";
import Fab from "@mui/material/Fab";
import { LANDLINE } from "../../configs";
import { Stack } from "@mui/material";
import SwitchLanguage from "./switchLan";
import logo from "../../assets/logo.png";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavgationBar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigation = [
    { name: t("navBar_title.home"), href: "/home", current: true },
    {
      name: t("navBar_title.insurance_claim"),
      href: "/insurance",
      current: false,
    },
    { name: t("navBar_title.accident"), href: "/accident", current: false },
    { name: t("navBar_title.repair"), href: "/repair", current: false },
    { name: t("navBar_title.rwc"), href: "/rwc", current: false },
    { name: t("navBar_title.contact"), href: "/contact", current: false },
  ];
  return (
    <Disclosure as="nav" className="bg-white sticky top-0 z-50">
      {({ open }) => (
        <Stack>
          <div className=" w-full ">
            <div className="relative flex h-20 items-center border-b-2 border-solid border-[#FE5D27]">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center  lg:items-stretch lg:justify-between lg:mx-24">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-6 w-auto max-lg:mx-5 "
                    src={logo}
                    alt="Your Company"
                    onClick={() => navigate("/home")}
                  />
                </div>

                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4  ">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          isActive ? "selected tab " : "tab"
                        }
                        // aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                    <div className="my-auto">
                      <Fab
                        size="small"
                        variant="extended"
                        sx={{ color: "white" }}
                        color="warning"
                      >
                        {/* contact us:{" "} */}
                        <ContactMethod contact={LANDLINE} type="tel" />
                      </Fab>
                    </div>
                  </div>
                </div>
                <SwitchLanguage />
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* switch lan */}
              </div>
            </div>
          </div>

          <DisclosurePanel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  as="a"
                  to={item.href}
                  className={({ isActive }) =>
                    isActive
                      ? "block rounded-md h-10 bg-[#FE5D27] py-2 px-3 text-white"
                      : classNames(
                          "text-black hover:bg-[#FE5D27] hover:text-white",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )
                  }
                  // aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
          </DisclosurePanel>
        </Stack>
      )}
    </Disclosure>
  );
}
