import "./index.css";

import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const SwitchLanguage = () => {
  const { i18n } = useTranslation();
  const handleLanguageChange = (lan) => {
    i18n.changeLanguage(lan);
    setLan(i18n.language);
  };
  const [lan, setLan] = useState("en");
  const lans = [
    { label: "English", lan: "en" },
    { label: "中文", lan: "zh" },
  ];
  const toggles = lans.map((l) => {
    let cls = "h-8 text-center w-16 leading-8 rounded-md ";
    let add_cls;
    if (l.lan === lan) {
      add_cls = "bg-[#FE5D27] text-white";
    }
    return (
      <span
        className={cls + " " + add_cls}
        onClick={() => {
          handleLanguageChange(l.lan);
        }}
      >
        {l.label}
      </span>
    );
  });
  return <div className="btn-container">{toggles}</div>;
};

export default SwitchLanguage;
