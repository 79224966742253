import "./index.css";

import ContactMethod from "../../comps/contacts";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import Fab from "@mui/material/Fab";
import { LANDLINE } from "../../configs";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import MinorCrashOutlinedIcon from "@mui/icons-material/MinorCrashOutlined";
import Paper from "@mui/material/Paper";
import React from "react";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

function InsuranceProcess() {
  const { t } = useTranslation();
  const sx = {
    width: "100%",
    height: "500px",
    padding: "35px",
    textAlign: "center",
    minWidth: "400px",
  };
  const paper_props = {
    elevation: 1,
  };
  const icon_size = "large";
  const PAPER_PADDING = "30px";
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 flex-wrap min-w-96">
      <Paper sx={sx} {...paper_props}>
        <MinorCrashOutlinedIcon fontSize={icon_size} />
        <Stack padding={PAPER_PADDING}>
          <span className="text-2xl">{t("insurancePage.step") + " 1"}</span>
          <br></br>
          <ol className="list-disc text-left">
            <li>{t("insurancePage.card1.bullet1")}</li>
            <br></br>
            <li>{t("insurancePage.card1.bullet2")}</li>
          </ol>
        </Stack>
      </Paper>
      <Paper sx={sx} {...paper_props}>
        <DocumentScannerOutlinedIcon fontSize={icon_size} />
        <Stack padding={PAPER_PADDING}>
          <span className="text-2xl">{t("insurancePage.step") + " 2"}</span>
          <br></br>
          <ol className="list-disc text-left">
            <li>{t("insurancePage.card2.bullet1") + ":"}</li>
            <br></br>
            <li>{t("insurancePage.card2.bullet2")}</li>
            <li>{t("insurancePage.card2.bullet3")}</li>
            <li>{t("insurancePage.card2.bullet4")}</li>
            <li>{t("insurancePage.card2.bullet5")}</li>
            <li>{t("insurancePage.card2.bullet6")}</li>
            <li>{t("insurancePage.card2.bullet7")}</li>
            <li>{t("insurancePage.card2.bullet8")}</li>
            <li>{t("insurancePage.card2.bullet9")}</li>
            <li>{t("insurancePage.card2.bullet10")}</li>
          </ol>
        </Stack>
      </Paper>
      <Paper sx={sx} {...paper_props}>
        <LocalPhoneOutlinedIcon fontSize={icon_size} />
        <Stack padding={PAPER_PADDING}>
          <span className="text-2xl">{t("insurancePage.step") + " 3"}</span>
          <br></br>
          <ol className="list-disc text-left">
            <li>{t("insurancePage.card3.bullet1")}</li>
            <br></br>
            <li>{t("insurancePage.card3.bullet2")}</li>
            <br></br>
            <li>{t("insurancePage.card3.bullet3")}</li>
          </ol>
          <div className="pt-12">
            <Fab
              size="large"
              variant="extended"
              sx={{ color: "white", zIndex: 10 }}
              color="warning"
            >
              <>
                <ContactMethod type="tel" contact={LANDLINE}>
                  SF AUTO:
                </ContactMethod>
              </>
            </Fab>
          </div>
        </Stack>
      </Paper>
      <Paper sx={sx} {...paper_props}>
        <LocalShippingOutlinedIcon fontSize={icon_size} />
        <Stack padding={PAPER_PADDING}>
          <span className="text-2xl">{t("insurancePage.step") + " 4"}</span>
          <br></br>
          <ol className="list-disc text-left">
            <li>{t("insurancePage.card4.bullet1")}</li>
            <br></br>
          </ol>
        </Stack>
      </Paper>
    </div>
  );
}

export default function InsurancePage() {
  return (
    <Stack sx={{ minHeight: "70vh" }} justifyContent="center">
      <Stack>
        <InsuranceProcess></InsuranceProcess>
      </Stack>
    </Stack>
  );
}
