import "./index.css";

import { ADDR, END_HOUR, LANDLINE, MOBILE, START_HOUR } from "../../configs";

import ContactMethod from "../../comps/contacts";
import React from "react";
const InfoBar = () => {
  return (
    <div
      className="justify-end flex bg-[#3D3451] items-center h-10 text-white max-sm:text-xs "
      style={{ paddingRight: "20px" }}
    >
      <div className=" max-sm:hidden">
        <span className=" max-lg:hidden ">Hours:</span>
        <span className="">
          {START_HOUR}- {END_HOUR}
        </span>
      </div>
      <span className="mx-8  max-lg:mx-2 max-sm:hidden">|</span>
      <div className="">
        <span className=" max-lg:hidden ">Address:</span>
        <span className="max-lg:text-sm">{ADDR}</span>
      </div>
      <span className="mx-8  max-lg:mx-2">|</span>
      <div className="">
        <span className=" max-lg:hidden ">Contact Number:</span>
        <span className="">
          <ContactMethod contact={LANDLINE} type="tel" shrink />{" "}
        </span>
        <span className="mx-8 max-lg:mx-2">|</span>
        <span className=" max-lg:hidden ">Mobile:</span>
        <span className="">
          <ContactMethod contact={MOBILE} type="mobile" shrink />{" "}
        </span>
      </div>
    </div>
  );
};

export default InfoBar;
