import "./index.css";

import {
  ChatBubbleOvalLeftEllipsisIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";

import Card from "@mui/material/Card";
import React from "react";
import { Stack } from "@mui/material";
import img from "../../assets/rwcherobanner.jpg";
import { useTranslation } from "react-i18next";

const features = [
  {
    name: "rwcPage.question1.title",
    description: "rwcPage.question1.content",
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    name: "rwcPage.question2.title",
    description: "rwcPage.question2.content",
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    name: "rwcPage.question3.title",
    description: "rwcPage.question3.content",
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    name: "rwcPage.question4.title",
    description: "rwcPage.question4.content",
    icon: HeartIcon,
    highlight: true,
  },
];

export default function RwcPage() {
  const { t } = useTranslation();
  return (
    <Card sx={{}}>
      <div className="bg-transparent py-24 sm:pb-20 sm:pt-0 ">
        <Stack
          width={"100%"}
          height={200}
          sx={{
            background: "#FE5D27",
            backgroundImage: `url(${img})`,
            backgroundSize: "fit",
            backgroundPosition: "center bottom",
          }}
        >
          <div className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center my-20 bg-[#FE5D27] py-3 w-1/2">
            {t("rwcPage.title")}
          </div>
        </Stack>
        <div className="mx-auto max-w-8xl px-6 lg:px-36">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  style={{
                    border: feature.highlight ? "2px solid #FE5D27" : "",
                    borderRadius: feature.highlight ? 10 : "",
                    padding: "20px",
                    // animation: feature.highlight ? 'borderAnimation 1s ease-in-out infinite alternate' : 'none',
                  }}
                  className={` ${
                    feature.highlight ? "feature-item highlight" : ""
                  }`}
                >
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div
                      className={
                        "mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-[#FE5D27]"
                      }
                    >
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {t(feature.name)}
                  </dt>
                  <dd className="mt-1 text-base leading-7 text-gray-600">
                    {t(feature.description)}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </Card>
  );
}
