import "./index.css";

import ConstructionIcon from "@mui/icons-material/Construction";
import Divider from "@mui/material/Divider";
import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AboutUsLeft = () => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ fontSize: 20 }} className="lg:max-w-4xl ">
      <Typography
        className="about-us-title max-2xl:text-center max-sm:text-lg"
        margin="20px 0"
        fontSize={40}
      >
        {t("about_us.title")}{" "}
        <ConstructionIcon fontSize="10px"></ConstructionIcon>
      </Typography>
      <Divider></Divider>
      <Typography
        className="max-2xl:text-center max-2xl:items-center "
        margin="20px 0"
      >
        {t("about_us.info")}
      </Typography>
    </Stack>
  );
};

export default AboutUsLeft;
