import xhslogo from "../../assets/xhslogo.svg";
const navigation = [
  {
    name: "YouTube",
    href: "https://www.xiaohongshu.com/user/profile/620e3384000000001000c7b7?xhsshare=WeixinSession&appuid=5a11b5a611be10174fcd39e7&apptime=1718624798&wechatWid=f4cdafba1d4df3be5008aef025eda824&wechatOrigin=menu",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        {/* <path
          fillRule="evenodd"
          d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
          clipRule="evenodd"
        /> */}
      </svg>
    ),
  },
];

export default function Footer() {
  return (
    <footer className="bg-[#FE5D27]">
      <div className="mx-auto max-w-7xl px-6 py-8 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-white hover:text-gray-500"
            >
              {/* <span className="sr-only">{item.name}</span> */}
              <img src={xhslogo} alt=""></img>
              <div className="h-full w-10" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-white">
            &copy; 2024 SF Auto, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
