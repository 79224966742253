import "./index.css";

import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import React from "react";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

export default function ContactMethod(props) {
  let href;
  if (props.type === "tel" || props.type === "mobile") {
    href = "tel:" + props.contact;
  } else if (props.type === "email") {
    href = "mailto:" + props.contact;
  }
  let shrink = props.shrink;
  let lgclsname = "max-lg:hidden";
  let clsname = "lg:hidden";

  if (!shrink) {
    clsname = "hidden";
    lgclsname = "";
  }
  return (
    <>
      <a href={href} className="contact_hyper">
        <span className={lgclsname}>
          {(props.children || "") + props.contact}
        </span>
        <span className={clsname}>
          {props.type === "email" ? (
            <EmailIcon />
          ) : props.type === "mobile" ? (
            <SmartphoneIcon />
          ) : (
            <LocalPhoneIcon />
          )}
        </span>
      </a>
    </>
  );
}
