import React from "react";

export default function ContentHero(props) {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b">
        <div className="flex mx-auto max-w-8xl pb-24 pt-10 sm:pb-32 lg:flex lg:justify-center lg:px-8 lg:py-10 lg:h-60 max-sm:py-0 max-sm:flex max-md:pb-4">
          <div className="flex items-center px-6 lg:px-0 lg:pt-0 max-sm:flex max-sm:items-center">
            <div className="mx-auto max-w-3xl ">
              <div className="max-w-3xl">
                <h1 className=" text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl max-sm:text-3xl ">
                  {props.title}
                </h1>
              </div>
            </div>
          </div>
          {/* this contain fit image */}
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-lg lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl"></div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32 max-sm:hidden" />
      </div>
    </div>
  );
}
