import "./App.css";
import "./i18n.js";

import { Navigate, Route, Routes } from "react-router-dom";

import AccidentPage from "./pages/accidentPage";
import ContactPage from "./pages/contactPage";
import Footer from "./comps/footer";
import InfoBar from "./comps/infoBar";
import InsurancePage from "./pages/insurancePage";
import MainPage from "./pages/mainPage";
import NavigationBar from "./comps/navgationBar";
import RepairPage from "./pages/repairPage";
import RwcPage from "./pages/rwcPage";

function App() {
  return (
    <div id="app">
      <InfoBar />
      <NavigationBar />
      <div className=" py-7 px-5 max-sm:px-0 max-sm:mx-0">
        <Routes>
          <Route path="/home" exact element={<MainPage />} />
          <Route path="/insurance" exact element={<InsurancePage />} />
          <Route path="/accident" exact element={<AccidentPage />} />
          <Route path="/repair" exact element={<RepairPage />} />
          <Route path="/rwc" exact element={<RwcPage />} />
          <Route path="/contact" exact element={<ContactPage />} />
          <Route path="*" exact element={<Navigate to="/home" />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
