import "./index.css";

import { Carousel } from "antd";
import { Fab } from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import c1 from "../../assets/carousel/1.jpeg";
import c2 from "../../assets/carousel/2.jpg";
import c3 from "../../assets/carousel/3.jpeg";
import c4 from "../../assets/carousel/4.jpg";
import { useTranslation } from "react-i18next";

// import ContactMethod from "../contacts";

function left(t) {
  return (
    <div className="flex bg-white mx-12 order-1 max-lg:order-2 ">
      <div className="text-base leading-8 text-gray-700 lg:max-w-xl ">
        {/* <p className="text-base font-semibold leading-7 text-[#FE5D27]">
                Our Mission
              </p> */}
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t("mainPage_content.title")}
        </h1>
        <div className="max-w-2xl text-lg leading-8 max-sm:text-sm">
          <p className="mt-6 font-[space-grotesk-spaces] ">
            {t("mainPage_content.banner_text")}
          </p>
          <p className="mt-8 ">
            <Stack direction="row" className="justify-between z-10">
              <Fab
                size="large"
                variant="extended"
                sx={{ color: "white", zIndex: 10 }}
                color="warning"
              >
                <span className="max-sm:text-xs">
                  Address:13 Treforest Drive, Clayton, VIC 3168
                </span>
              </Fab>
              {/* <Fab size ='large' variant="extended" sx={{color:'white',zIndex:10}} color="warning" >
                  <><ContactMethod type='tel' contact='{LANDLINE}'> </ContactMethod></>
                  </Fab> */}
            </Stack>
          </p>
        </div>
      </div>
    </div>
  );
}
function right() {
  return (
    <div className="order-2 lg:pr-4 max-lg:order-1 ">
      <Carousel autoplay arrows>
        <div className="max-sm:h-60 max-sm:w-full">
          <img
            className="object-cover rounded-xl max-sm:scale-95"
            style={{ width: 900, height: 430 }}
            src={c1}
            alt=""
          ></img>
        </div>
        <div className="max-sm:h-60 max-sm:w-full">
          <img
            className="object-cover rounded-xl "
            style={{ width: 900, height: 430 }}
            src={c2}
            alt=""
          ></img>
        </div>
        <div className="max-sm:h-60 max-sm:w-full">
          <img
            className="object-cover rounded-xl "
            style={{ width: 900, height: 430 }}
            src={c3}
            alt=""
          ></img>
        </div>
        <div className="max-sm:h-60 max-sm:w-full">
          <img
            className="object-cover rounded-xl max-sm:scale-95"
            style={{ width: 900, height: 430 }}
            src={c4}
            alt=""
          ></img>
        </div>
      </Carousel>
    </div>
  );
}

function Banner() {
  const { t } = useTranslation();
  return (
    <Stack className="bg-white " direction="row">
      <div className="mx-16 w-full px-6 lg:px-8 lg:py-10 max-sm:mx-0 max-sm:px-0">
        <div className="mx-auto grid max-w-6xl grid-cols-1 items-start gap-x-2 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2 ">
          {left(t)}
          {right()}
        </div>
      </div>
    </Stack>
  );
}

export default Banner;
