import "./index.css";

import { ImageList, useMediaQuery } from "@mui/material";

import ImageListItem from "@mui/material/ImageListItem";
import React from "react";
import img1 from "../../../assets/imageList/imagelist1.jpg";
import img2 from "../../../assets/imageList/imagelist2.jpg";
import img3 from "../../../assets/imageList/imagelist3.jpg";
import img4 from "../../../assets/imageList/imagelist4.jpg";
import img5 from "../../../assets/imageList/imagelist5.jpg";
import img6 from "../../../assets/imageList/imagelist6.jpeg";
import img7 from "../../../assets/imageList/imagelist7.jpg";
import img8 from "../../../assets/imageList/imagelist8.jpg";
import img9 from "../../../assets/imageList/imagelist9.jpg";
import { useTheme } from "@mui/system";

export function StandardImageList() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ImageList
      sx={{ width: matches ? "350px" : "500px", height: 480 }}
      rowHeight="auto"
      cols={matches ? 2 : 3}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}`}
            src={`${item.img}`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: img1,
    title: "Breakfast",
  },
  {
    img: img2,
    title: "Burger",
  },
  {
    img: img3,
    title: "Camera",
  },
  {
    img: img4,
    title: "Coffee",
  },
  {
    img: img5,
    title: "Hats",
  },
  {
    img: img6,
    title: "Honey",
  },
  {
    img: img7,
    title: "Basketball",
  },
  {
    img: img8,
    title: "Fern",
  },
  {
    img: img9,
    title: "Mushrooms",
  },
];
const AboutUsRight = () => {
  return (
    <>
      <StandardImageList></StandardImageList>
    </>
  );
};

export default AboutUsRight;
