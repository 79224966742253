import "./index.css";

import {
  ADDR,
  EMAIL,
  END_HOUR,
  LANDLINE,
  MOBILE,
  START_HOUR,
} from "../../configs";

import ContactMethod from "../../comps/contacts";
import React from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ContactPage() {
  const { t } = useTranslation();
  return (
    <Stack>
      <div className="grid grid-cols-1 items-center min-h-[71.5vh] xl:grid-cols-2 ">
        <div className="justify-self-center">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.3858533440116!2d145.12905937653204!3d-37.92141537194876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad66b35613d90f3%3A0x4c6e0c6d5b3f0311!2sSF%20AUTO%20GROUP!5e0!3m2!1sen!2sau!4v1718189971722!5m2!1sen!2sau"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div
          className="content-evenly text-3xl justify-self-center"
          direction="column"
        >
          <Stack className="my-7" direction="row">
            <span className="mr-6">{t("contact_us.hours")}:</span>
            {START_HOUR} - {END_HOUR}
          </Stack>
          <Stack className="my-7" direction="row">
            <span className="mr-6">{t("contact_us.landline")}:</span>{" "}
            <ContactMethod contact={LANDLINE} type="tel" shrink={false} />
          </Stack>
          <Stack className="my-7" direction="row">
            <span className="mr-6">{t("contact_us.mobile")}:</span>{" "}
            <ContactMethod contact={MOBILE} type="mobile" shrink={false} />
          </Stack>
          <Stack className="my-7" direction="row">
            <span className="mr-6">{t("contact_us.email")}:</span>{" "}
            <ContactMethod contact={EMAIL} type="email" />
          </Stack>
          <Stack className="my-7" direction="row">
            <span className="mr-6">{t("contact_us.address")}:</span>
            {ADDR}
          </Stack>
        </div>
      </div>
    </Stack>
  );
}
